<template>
    <NuxtLayout>
        <NuxtPage
            class="layout__main"
        />
    </NuxtLayout>
</template>

<script setup lang="ts">
import {useSiteInjection} from '~/composables/useSiteInjection';

useSiteInjection();
</script>
