import type {SiteConfig, SiteMap} from '~/interfaces/multisite';
import {useSiteDataStore} from '~/store/siteDataStore';
import {reactive} from 'vue';

const getHost = (url: URL | undefined): string => {
    return `${url?.protocol}//${url?.hostname}${url?.port ? `:${url?.port}` : ''}`;
};

export const useMultisite = () => {
    const siteData = useSiteDataStore();
    const runtimeConfig = useRuntimeConfig() as {
        public: {
            sites: SiteMap;
            defaults: SiteConfig;
        };
    };

    const url = useRequestURL();

    const activeSite = computed<SiteConfig>(() => {
        let result;
        if (runtimeConfig.public.sites[url?.hostname]) {
            result = runtimeConfig.public.sites[url?.hostname];
        } else {
            result = runtimeConfig.public.defaults;
        }

        return result;
    });

    const variables = reactive({
        site: [activeSite.value.handle],
    });

    const featureFlag = (featureId: string): boolean => {
        return runtimeConfig.public.sites[url?.hostname]?.enable?.includes(featureId);
    };

    const getSiteId = async(handle: string): Promise<string | undefined> => {
        const sites = siteData.sites.value ?? [];
        const site = sites.find((siteConfig: SiteConfig) => siteConfig.handle === handle);
        return site?.id;
    };
    return {
        sites: runtimeConfig.public.sites,
        siteIds: siteData?.sites?.map((site: SiteConfig) => ({id: site.id, handle: site.handle})),
        activeSite,
        host: getHost(url),
        variables,
        featureFlag,
        getSiteId,
    };
};
