import {createClient, defaultPlugins} from 'villus';

// @ts-ignore - no types
import {bearerToken} from '@digitalnatives/villus-plugins';

export const useGraphqlClient = () => {
    const { public: { apiEndpoint, apiToken} } = useRuntimeConfig() as {
        public: {
            apiEndpoint: string,
            apiToken: string,
        };
    };

    return createClient({
        url: apiEndpoint,
        use: [bearerToken(apiToken), ...defaultPlugins()]
    });
};
