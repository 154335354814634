// Define a type guard to check if a value is an object and not an array
export const isObject = (value: unknown): value is Record<string, unknown> => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
};


// Check if key(s) exist in object
export const hasOwnProperty = (obj: unknown, key: string): key is keyof typeof obj => {
    // Ensure obj is an object and key is defined
    if (isObject(obj) && key) {
        return Object.prototype.hasOwnProperty.call(obj, key);
    }
    return false;
};

// Check if key exists in object and is a string
export const hasString = <T extends object, K extends keyof T>(
    obj: T,
    key: K
): obj is T & Record<K, string> =>
        key in obj && // Check if key exists in object
        typeof obj[key] === 'string' && // Check if value is a string
        (obj[key] as string).length > 0; // Check if string has length
