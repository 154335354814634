import type {EtherSeo} from '~/interfaces/seo';

export const getRobots = (seo: EtherSeo) => {
    let content = 'all';

    if (seo?.advanced && seo.advanced?.robots) {
        content = seo.advanced.robots.join(', ');
    }

    return { hid: 'robots', name: 'robots', content };
};
