<template>
    <BaseSection>
        <template #title>
            <h1 v-if="error && error.statusCode === 404">Page not found</h1>
            <h1 v-else>An error occurred</h1>
        </template>
    </BaseSection>

    <template v-if="error.statusCode === 404">
        <p class="lead">
            This page could not be found. The link might be broken or the page moved.
        </p>
        <BaseSection>
            <QuicklinksList
                class="QuicklinksList--large"
                :links="[
                    { text: 'Search through our website', uri: '/search' },
                    { text: 'Go back to the homepage', uri: '/' },
                    { text: 'Find help on the contact page', uri: '/contact' },
                ]"
            />
        </BaseSection>
    </template>

    <p
        v-else-if="error && error.message"
        class="lead"
    >
        {{ error.message }}
    </p>
</template>

<script setup>
const { activeSite } = useMultisite();

const props = defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const title = ref('');
const description = ref('');

if (props.error.statusCode === 404) {
    title.value = 'Page not found';
    description.value = 'The page you\'ve requested cannot be found';
} else {
    title.value = 'An error occurred';
    description.value = props.error.message;
}

const data = computed(() => {
    return {
        title: title.value,
        description: description.value
    };
});

const head = useSeo(data, data, undefined, activeSite);

useHead(head);
</script>
