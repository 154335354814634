import revive_payload_client_8XDF1M2joS from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WipeXFarpW from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_b94CfdzaSr from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6b4mxJoj0d from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@20.16.11_less@4.2_xr6lmvnt2ti4xw7khwswmrrqxq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_qFpu2wtQwq from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mw5LdPvgA9 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lBdEoGGMqu from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3Kvdw7Ilup from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_HgJMif44Ct from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/uroweb/uroweb-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_61SCxA5qB2 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_wxujgczyisgeuvdumzrpdsaa2i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Q78LUYI4C4 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1_rollup@4.24.0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_1gYBbyq1Em from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watche_bdvyapszpdieyjcvdwuskgtfqq/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_g4Z5dgbZVP from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_EDVYz58coz from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import globals_qEnHRCYwqu from "/builds/uroweb/uroweb-frontend/plugins/globals.ts";
import gtm_cXsUZobmSF from "/builds/uroweb/uroweb-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_8XDF1M2joS,
  unhead_WipeXFarpW,
  router_b94CfdzaSr,
  _0_siteConfig_6b4mxJoj0d,
  payload_client_qFpu2wtQwq,
  navigation_repaint_client_mw5LdPvgA9,
  check_outdated_build_client_lBdEoGGMqu,
  chunk_reload_client_3Kvdw7Ilup,
  plugin_vue3_HgJMif44Ct,
  components_plugin_KR1HBZs4kY,
  prefetch_client_61SCxA5qB2,
  plugin_Q78LUYI4C4,
  plugin_1gYBbyq1Em,
  plugin_g4Z5dgbZVP,
  nuxt_plugin_EDVYz58coz,
  globals_qEnHRCYwqu,
  gtm_cXsUZobmSF
]