import {useSiteDataStore} from '~/store/siteDataStore';

export const useSiteInjection = () => {

    const { activeSite, host } = useMultisite();
    const fallback = computed(() => `${host}/eau.svg`);
    const siteData = useSiteDataStore();

    if(activeSite.value.name) {
        provide('siteName', activeSite.value.name);
    }

    provide('navLogoUrl', toValue(siteData.assets)?.navigation?.url ?? fallback);
    provide('footerLogoUrl', toValue(siteData.assets)?.footer?.url ?? fallback);

    if (toValue(siteData.assets)?.favicon) {
        useFavicon(toValue(siteData.assets).favicon);
    } else {
        useFavicon({
            url180: '/apple-touch-icon-180x180.png',
            url512: '/pwa-512x512.png',
            url192: '/pwa-192x192.png',
            url64: '/favicon-64x64.png'
        });
    }

    useHead({
        title: activeSite.value.name,
        meta: [
            {
                name: 'description',
                content: `${activeSite.value.name} - ${activeSite.value.seo.description}`
            },
            ...(activeSite.value?.tags ?? [])
        ]
    });

};
