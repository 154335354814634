<template>
    <NuxtLayout
        name="default"
    >
        <main class="layout__main">
            <BaseError :error="error" />
        </main>
    </NuxtLayout>
</template>

<script setup lang="ts">
import {useSiteInjection} from '~/composables/useSiteInjection';
defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

useSiteInjection();
</script>

<style lang="less"></style>
