<template>
    <component
        :is="element"
        v-bind="attributes"
        class="quicklink"
        :class="{
            'active': route.path === props.uri && route.path?.length > 1
        }"
    >
        <span class="quicklink__text">
            <slot />
        </span>
        <BaseIcon
            class="quicklink__icon dn-icon--square"
            :icon="checkIsExternal(uri) ? 'arrow-up-right' : 'arrow-right'"
        />
    </component>
</template>

<script setup>
const { checkIsExternal } = useUri();
const route = useRoute();

const props = defineProps({
    uri: {
        type: String,
        required: false,
        default: null
    }
});

const element = computed(() => {
    if (!props.uri) {
        return 'span';
    }
    if (checkIsExternal(props.uri)) {
        return 'a';
    }
    return resolveComponent('NuxtLink');
});
const attributes = computed(() => {
    if (!props.uri) {
        return null;
    }
    if (checkIsExternal(props.uri)) {
        return {
            href: props.uri,
            target: '_blank',
            rel: 'noopener noreferrer'
        };
    }
    return {
        to: props.uri
    };
});
</script>

<style lang="less">
.quicklink {
    .typography-text;

    display: flex;
    text-decoration: none;
    color: var(--color-darkest);
    border-bottom: 1px solid var(--color-gray-200);

    &:hover, &:focus {
        color: var(--color-primary);
        cursor: pointer;
    }

    &:active,
    &.active {
        color: var(--color-primary-dark);
    }
}

.quicklink__text {
    flex: 1 0 auto;
    padding: 1rem 0;
}

.quicklink__icon {
    margin-left: var(--spacing-md);
}

.quicklink__icon.dn-icon {
    display: flex;
    align-items: center;

    font-size: 1.5rem;
    line-height: 0;
    transform: translateX(0);
    transition: transform var(--transition-fast);

    .quicklink:hover & {
        transform: translateX(2px);
    }
}

.quicklinks-list--large .quicklink {
    font-size: var(--font-size-lg);

    .quicklink__icon.dn-icon {
        font-size: 1.75rem;
    }
}
</style>
