import {hasOwnProperty} from '~/utils/obj';
import type {Image} from '~/interfaces/seo';

export const getSeoImage = (
    imageSets: Image[][],
    properties = ['seo', 'large'] as const
): string | undefined => {
    // eg entry.overviewImage[], entry.headerImage[]
    for (const imageSet of imageSets) {
        if (!imageSet || !imageSet.length) {
            continue;
        }
        const firstImage = imageSet[0];
        for (const property of properties) {
            if (hasOwnProperty(firstImage, property)) {
                return firstImage[property];
            }
        }
    }
    return undefined;
};
