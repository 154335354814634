import type {EtherSeo, SocialMeta} from '~/interfaces/seo';
import {hasString} from '~/utils/obj';

export const getFbMetaFields = (seo: EtherSeo): SocialMeta[] => {
    return getEtherSocialFields(seo, 'facebook');
};

export const getTwMetaFields = (seo: EtherSeo): SocialMeta[] => {
    return getEtherSocialFields(seo, 'twitter');
};

const getEtherSocialFields = (
    seo: EtherSeo,
    platformKey: 'facebook' | 'twitter'
) => {
    const hid = platformKey === 'facebook' ? 'og' : 'twitter';

    if (seo?.social && seo.social?.[platformKey]) {
        const meta: SocialMeta[] = [];
        const platform = seo.social[platformKey];

        // Extract image from social object or fallback to featured image
        const image = platform?.image?.url ?? seo?.featuredimage?.urlLarge;

        if (image) {
            meta.push({hid: `${hid}:image`, property: `${hid}:image`, content: image});
        }

        const fields = ['title', 'description'] as const;

        const getFieldContent = (obj: Record<string, any>, field: typeof fields[number]) => {
            return hasString(obj, field) && obj[field];
        };

        for (const field of fields) {
            // Extract content from social object or fallback to seo object
            const content = getFieldContent(platform, field) || getFieldContent(seo, field);

            if (content) {
                meta.push({ hid: `${hid}:${field}`, property: `${hid}:${field}`, content });
            } else {
                console.warn(`useSeo: getSocialFields: ${field} is empty for ${platformKey}`);
            }
        }

        return meta.filter(Boolean);
    }
    return [];
};
