import { trimEnd } from 'lodash-es';
import type { Redirect } from '../types/Redirect';

export default defineNuxtRouteMiddleware(async(to) => {
    const { redirects } = await useRedirects();

    const routePath = trimEnd(to.path, '/');

    const foundRedirect = redirects.value?.find((currentRedirect) => {
        if (currentRedirect.uri === routePath) {
            return true;
        }

        return new RegExp(`^${currentRedirect.uri}$`, 'i').test(routePath);
    });

    if (foundRedirect) {
        return redirectTo(routePath, foundRedirect);
    }
});

export function redirectTo(route: string, foundRedirect: Redirect) {
    let path = foundRedirect.to;

    // if we find regex $ replace tags in the redirect, use regex to replace the uri.
    if (/\$/i.test(path)) {
        path = route.replace(new RegExp(`^${foundRedirect.uri}$`, 'i'), foundRedirect.to);
    }

    const redirectCode = parseInt(foundRedirect.type, 10);
    return navigateTo(path, { redirectCode, external: true });
}
