import {cleanString} from '~/utils/string';
import type {EtherEntry} from '~/interfaces/seo';

export const getSeoDescription = (entry: EtherEntry): string => {
    if (entry?.searchDescription) {
        return entry.searchDescription;
    }
    if (entry?.introText) {
        return cleanString(entry.introText);
    }
    if (entry?.intro) {
        return cleanString(entry.intro);
    }
    return '';
};
