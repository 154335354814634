import { defineStore } from 'pinia';
import { ref } from 'vue';

type Assets = {
    [key: string]: string | {
        [size: string]: string;
    };
}

type Translations = {
    [lng: string]: {
        [cat: string]: {
            [key: string]: string;
        };
    };
}

type Site = {
    id: string;
    handle: string;
    enabled: boolean;
    primary?: boolean;
    language?: string;
}

type Sites = Site[];

export const useSiteDataStore = defineStore('siteData', () => {
    const assets = ref<Assets | null>(null);
    const translations = ref<Translations>({});
    const sites = ref<Sites>(null);
    const isFetched = ref<boolean>(false);

    const setSiteData = (data) => {
        translations.value = processTranslations(toValue(data)?.strings);
        assets.value = processAssets(toValue(data)?.assets);
        sites.value = toValue(data)?.sites;

        isFetched.value = true;
    };

    return {
        sites,
        assets,
        translations,
        setSiteData,
        isFetched
    };
});

const processTranslations = (data) => {
    const translationsMap = {};
    if (!data) {
        return translationsMap;
    }

    data.forEach((item) => {
        const { language, category, key, message } = item;

        if (!translationsMap[language]) {
            translationsMap[language] = {};
        }
        if (!translationsMap[language][category]) {
            translationsMap[language][category] = {};
        }
        translationsMap[language][category][key] = message;
    });

    return translationsMap;
};

const processAssets = (data) => {
    const assetsMap = {};
    if (!data) {
        return assetsMap;
    }

    Object.keys(data).forEach((key) => {
        const prop = data[key];
        if (Array.isArray(prop)) {
            assetsMap[key] = prop[0];
        } else {
            assetsMap[key] = prop;
        }
    });

    return assetsMap;
};
