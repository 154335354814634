export default function() {
    const uriFromEntryFields = (entry) => {
        if (entry.linkInternal && entry.linkInternal.length) {
            const uri = entry.linkInternal[0]?.uri;
            return uri === '__home__' ? '/' : '/' + uri;
        }

        return entry.linkExternal;
    };

    const checkIsExternal = (url) => {
        return url?.startsWith('http');
    };

    return {
        uriFromEntryFields,
        checkIsExternal
    };
}
