import { villusBugsnag, craftPreview, bearerToken } from '@digitalnatives/villus-plugins';
import { defaultPlugins } from 'villus';

export default defineGraphqlConfig(() => {
    const { public: { apiEndpoint, apiToken} } = useRuntimeConfig();
    return {
        villus: {
            url: apiEndpoint,
            use: [villusBugsnag(), bearerToken(apiToken), craftPreview(), ...defaultPlugins()],
        }
    };
});
