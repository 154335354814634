import Redirects from '~/graphql/queries/Redirects.graphql';
import {useGraphqlClient} from '~/composables/useGraphqlClient';

export const useRedirects = async() => {
    const redirectStore = useRedirectsStore();
    const { redirects } = storeToRefs(redirectStore);

    if (redirects.value === null) {
        redirects.value = await fetch();
    }

    return {
        redirects,
    };
};

const fetch  = async() => {
    const { activeSite } = useMultisite();

    const client = useGraphqlClient();
    const { data } = await client.executeQuery({
        query: Redirects,
        variables: {
            handle: activeSite.value.handle.toString()
        }
    });

    return data?.redirects ?? [];
};
